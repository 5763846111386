import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Features from "../components/Features";
// import Testimonials from "../components/Testimonials";
// import Pricing from "../components/Pricing";
// import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import FullWidthImage from "../components/FullWidthImage";
import logoOffensive from "../img/logo-offensive.png";
import Content, { HTMLContent } from '../components/Content'

// eslint-disable-next-line
export const ContributePageTemplate = ({
  image,
  title,
  heading,
  description,
  intro,
  main,
  // testimonials,
  fullImage,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content
  const heroImage = getImage(image) || image;
  const fullWidthImage = getImage(fullImage) || fullImage;

  return (
    <div className="content">
      <FullWidthImage img={heroImage} title={title} imgPosition = "top right" />
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-7 is-offset-1">
                <h3 className="has-text-weight-semibold is-size-2">
                  {heading}
                </h3>
                <p>{description}</p>
              </div>
            </div>
            <div className="columns">
              <div className="column is-10 is-offset-1">
              <div className="columns is-multiline">
                <div className="column is-6 has-text-centered">
                <div style={{ textAlign: "center"}}><section className="section">
                  <a href="https://opencollective.com/fonds-graine-de-moutarde" target="_blank">
                    <img src="https://opencollective.com/fonds-graine-de-moutarde/donate/button@2x.png?color=blue" width="300" />
                  </a><br />
                  Contribuer sur <a href="https://opencollective.com">opencollective.com</a>
                  </section>
                </div></div>
                <div className="column is-6 has-text-centered">
                <div style={{ textAlign: "center"}}><section className="section">
                  <iframe title="Dons via HelloAsso" id="haWidget" allowtransparency="true" src="https://www.helloasso.com/associations/graine-de-moutarde-fonds-de-dotation/formulaires/1/widget-bouton" style={{ width: "100%", height: "70px", border: "none" }}></iframe>Contribuer via <a href="https://www.helloasso.com" rel="nofollow">HelloAsso</a>
                  </section>
                </div></div>
                </div>
                <Features gridItems={intro.blurbs} />
                <div className="columns">
                  <div className="column is-7">
                    <h3 className="has-text-weight-semibold is-size-3">
                      {main.heading}
                    </h3>
                    <p>{main.description}</p>
                  </div>
                </div>
                {/* <div className="tile is-ancestor">
                  <div className="tile is-vertical">
                    <div className="tile">
                      <div className="tile is-parent is-vertical">
                        <article className="tile is-child">
                          <PreviewCompatibleImage imageInfo={main.image1} />
                        </article>
                      </div>
                      <div className="tile is-parent">
                        <article className="tile is-child">
                          <PreviewCompatibleImage imageInfo={main.image2} />
                        </article>
                      </div>
                    </div>
                    <div className="tile is-parent">
                      <article className="tile is-child">
                        <PreviewCompatibleImage imageInfo={main.image3} />
                      </article>
                    </div>
                  </div>
                </div>
                <Testimonials testimonials={testimonials} /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <FullWidthImage img={fullWidthImage} imgPosition={"bottom"} />
      <div className="has-text-centered" style={{ marginTop: "-8em", position: "relative" }} >
        <img
            src={logoOffensive}
            alt="L'OFFENSIVE"
            style={{ width: "16em", height: "16em" }}
          />
      </div>
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <PageContent content={content} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

ContributePageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  main: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    // image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    // image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    // image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  // testimonials: PropTypes.array,
  fullImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
};

const ContributePage = ({ data }) => {
  const { markdownRemark: page } = data

  return (
    <Layout>
      <ContributePageTemplate
        image={page.frontmatter.image}
        title={page.frontmatter.title}
        heading={page.frontmatter.heading}
        description={page.frontmatter.description}
        intro={page.frontmatter.intro}
        main={page.frontmatter.main}
        // testimonials={page.frontmatter.testimonials}
        fullImage={page.frontmatter.full_image}
        content={page.html}
        contentComponent={HTMLContent}
      />
    </Layout>
  );
};

ContributePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ContributePage;

export const productPageQuery = graphql`
  query ContributePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
              }
            }
            text
          }
        }
        main {
          heading
          description
        }

        full_image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
